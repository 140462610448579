<template>
  <div class="container policy-wrapper">
    <div class="header d-flex align-items-center">
      <img @click="$router.go(-1)" src="../assets/img/header/back-copy.svg" alt="">
      <div class="title">{{ $t('policy.title') }}</div>
    </div>
    <div class="policy-content">
      <div class="shadow-wrapper">
        <perfect-scrollbar ref="scrollbar"
                           :class="{'shadow-gradient':isShadow}"
                           v-html="$t('policy.content')"
                           @scroll="checkShadow($event)">
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
let height = 0;
export default {
  name: "Policy",
  data() {
    return {
      isShadow: true
    }
  },
  methods: {
    checkShadow(ev) {
      if (!height) height = ev.target.scrollHeight;
      this.isShadow = ev.target.scrollTop + ev.target.clientHeight < height;
    }
  }
}
</script>
